import { initializeApp } from 'firebase/app';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: 'AIzaSyALkwxdhNukMESVW4xzrJu6KUCPxUD4gHE',
    authDomain: 'autotrack-323ef.firebaseapp.com',
    projectId: 'autotrack-323ef',
    storageBucket: 'autotrack-323ef.appspot.com',
    messagingSenderId: '1038856026149',
    appId: '1:1038856026149:web:ba212fe32e703fafb5a7cc',
    measurementId: 'G-Q5ZCG1Y09V',
};

const FIREBASE_VAPID_KEY =
    'BBVkxjQbqG7rIJZ8qPM9GKTLcGwlpGvcQvdhkbJNBXzrTvHGhfxIO33o37AtYNaEyiSky04LardH5ivdr4-3Pb0';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const auth = getAuth(app);

const db = getFirestore(app);
const storageFirebase = getStorage(app);
const googleProvider = new GoogleAuthProvider();

// Retrieve an instance of Firebase Messaging so that it can handle background
const requestForToken = () => {
    return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
        .then(currentToken => {
            if (currentToken) {
                return currentToken;
            } else {
                alert(
                    'No registration token available. Request permission to generate one.'
                );
                return null;
            }
        })
        .catch(err => {
            alert('An error occurred while retrieving token - ' + err);
            return null;
        });
};

onMessage(messaging, ({ notification }) => {
    if (!notification) return;
    new Notification(notification?.title, {
        body: notification?.body,
        icon: notification?.icon,
    });
});

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        if (!user) return;
        const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, 'users'), {
                uid: user.uid,
                name: user.displayName,
                authProvider: 'google',
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        localStorage.setItem('accessToken', res.user.accessToken);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, 'users'), {
            uid: user.uid,
            name,
            authProvider: 'local',
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const sendPasswordReset = async email => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset link sent!');
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    requestForToken,
    messaging,
    getToken,
    FIREBASE_VAPID_KEY,
    onMessage,
    onBackgroundMessage,
    storageFirebase,
};
