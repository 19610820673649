import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/reducers';
import { NavigationProvider } from '../hooks/useNavigate';
import { LayoutType } from '../utils/enums';
import { notification as Notification, NotificationArgsProps } from 'antd';
import { ICCheckFill, ICClose, ICWarning } from '../icons';
import { ScrollTop } from '../hooks/useScrollTop';
import AntdWrapApp from '../message';
import { TBreadcrumbs } from '../layout/components/breadcrumbs';
import { AdminLayout } from '../layout';
import { useMe } from '../hooks/useMe';
import { TNotification } from '../interface/user/user';
import {
    getSignalRConnection,
    startSignalRConnection,
} from '../utils/signalrClient';
import { ackReceived, searchNotification } from '../api/notification';
import {
    NotificationTypeMode,
    TNotificationAction,
    TSearchNotification,
} from '../api/notification/type';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type TGeneralPageDataProps = {
    openNotification: (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode
    ) => void;
    breadcrumbs: TBreadcrumbs[];
    setBreadcrumbs: React.Dispatch<React.SetStateAction<TBreadcrumbs[]>>;
    titlePage: string;
    setTitlePage: React.Dispatch<React.SetStateAction<string>>;
    notifications: TNotification[];
    setNotifications: React.Dispatch<React.SetStateAction<TNotification[]>>;
};

export const GeneralPageDataContext =
    React.createContext<TGeneralPageDataProps>({} as TGeneralPageDataProps);
export const useGeneralPageData = () =>
    React.useContext(GeneralPageDataContext);

type GeneralPageProps = {
    page: React.ReactElement;
    isPrivate?: boolean;
    layout?: LayoutType;
    classes?: string;
};
export const GeneralPage: React.FC<GeneralPageProps> = ({
    page,
    isPrivate = false,
    classes,
    layout = LayoutType.ADMIN,
}) => {
    const navigate = useNavigate();
    const [api, contextHolder] = Notification.useNotification();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [breadcrumbs, setBreadcrumbs] = React.useState<TBreadcrumbs[]>([]);
    const [titlePage, setTitlePage] = React.useState<string>('');
    const [notifications, setNotifications] = React.useState<TNotification[]>(
        []
    );
    const { userId, getMe } = useMe();
    type NotificationPlacement = NotificationArgsProps['placement'];

    const openNotification = (
        type: NotificationType,
        title: React.ReactNode,
        description?: React.ReactNode,
        placement?: NotificationPlacement
    ) => {
        Notification.destroy();
        if (type === 'success') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'info') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'warning') {
            api[type]({
                icon: <ICCheckFill />,
                message: (
                    <span className="text-high-em text-body-bold">{title}</span>
                ),
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
        if (type === 'error') {
            return api[type]({
                icon: <ICWarning fill="#F03D3D" />,
                message: (
                    <span className="text-body-bold text-high-em">{title}</span>
                ),
                // duration: 99999,
                description,
                placement: placement || 'bottom',
                className: 'middle-notification',
                closeIcon: <ICClose height={18} width={18} fill={'#4F4B5C'} />,
            });
        }
    };

    const [pageContent, setPageContent] = React.useState<React.ReactElement>(
        <></>
    );

    const setDataNotification = (data: any) => {
        if (typeof data === 'string' && data?.startsWith('{')) {
            const newNotification = JSON.parse(data);
            if (typeof newNotification !== 'object') {
                return;
            }
            if (newNotification?.UserId !== userId) {
                return;
            }
            setNotifications(prev => {
                const prevNotifications = Array.isArray(prev) ? prev : [];
                const newNotificationTemp = {
                    createdAt: newNotification?.CreatedAt,
                    remainTime: newNotification?.RemainTime,
                    serviceDetailId: newNotification?.ServiceDetailId,
                    serviceId: newNotification?.ServiceId,
                    serviceName: newNotification?.ServiceName,
                    signalRNotificationId:
                        newNotification?.SignalRNotificationId,
                    userId: newNotification?.UserId,
                };
                const exists = prevNotifications.some(
                    item =>
                        item?.signalRNotificationId ===
                        newNotificationTemp?.signalRNotificationId
                );

                if (prevNotifications.length === 0) {
                    return [newNotificationTemp];
                }
                if (exists) {
                    return prevNotifications.map(item =>
                        item?.signalRNotificationId ===
                        newNotificationTemp?.signalRNotificationId
                            ? newNotificationTemp
                            : item
                    );
                }
                return [newNotificationTemp, ...prevNotifications];
            });
            openNotification(
                'info',
                'Notification',
                newNotification?.ServiceName
            );
            if (
                newNotification?.SignalRNotificationId &&
                newNotification?.UserId
            ) {
                setAckReceived(
                    newNotification?.SignalRNotificationId,
                    newNotification?.UserId
                );
            }
        }
    };

    const initSignalR = async () => {
        try {
            await startSignalRConnection();
            const connection = await getSignalRConnection();
            if (!connection) {
                return;
            }
            connection?.on(
                'NotificationHub',
                (user: string, message: string) => {
                    setDataNotification(message);
                }
            );
        } catch (error) {
            // TODO: handle error
        }
    };

    const loadNotification = async (userIdVal: number | string) => {
        try {
            const payload: TSearchNotification = {
                userId: userIdVal,
                type: NotificationTypeMode.All,
                page: 1,
                pageSize: 10,
            };
            const result = await searchNotification(payload);
            if (result.status === 200) {
                const newData = result?.data?.data || [];
                setNotifications(prev => {
                    return [...prev, ...newData];
                });
            }
        } catch (error) {
            openNotification('error', 'Error when load notification');
        }
    };

    const setAckReceived = async (
        signalRNotificationId: number,
        userIdVal: number | string
    ) => {
        try {
            const payload: TNotificationAction = {
                userId: userIdVal,
                signalRNotificationId: signalRNotificationId,
            };
            await ackReceived(payload);
        } catch (error) {
            openNotification('error', 'Error when set ack received');
        }
    };

    const initData = async () => {
        try {
            await getMe();
            await initSignalR();
        } catch (error) {
            openNotification('error', 'Error when init data');
        }
    };

    React.useEffect(() => {
        if (isPrivate) {
            initData();
            if (userId) loadNotification(userId);
        }
    }, [userId]);

    React.useEffect(() => {
        if (classes) {
            document.body.className = classes;
        } else {
            document.body.className = '';
        }
        setTitlePage('');
        if (
            (isPrivate || typeof isPrivate === 'undefined') &&
            !userInfo.token
        ) {
            navigate('/login');
        } else {
            if (layout === LayoutType.ADMIN) {
                return setPageContent(<AdminLayout>{page}</AdminLayout>);
            } else {
                setPageContent(page);
            }
        }
    }, [page, userInfo, isPrivate, classes, navigate, layout]);

    return (
        <NavigationProvider>
            <GeneralPageDataContext.Provider
                value={{
                    openNotification,
                    breadcrumbs,
                    setBreadcrumbs,
                    titlePage,
                    setTitlePage,
                    notifications,
                    setNotifications,
                }}
            >
                <AntdWrapApp />
                {contextHolder}
                {pageContent}
                <ScrollTop />
            </GeneralPageDataContext.Provider>
        </NavigationProvider>
    );
};
