import { ApiInstance } from '..';
import { convert2QueryString } from '../../utils/convert2QueryString';
import { TNotificationAction, TSearchNotification } from './type';

export const searchNotification = (payload: TSearchNotification) => {
    const requestURL =
        '/api/signalRNotifications/all?' + convert2QueryString(payload);
    return ApiInstance.getInstance().get(requestURL);
};

export const ackReceived = (payload: TNotificationAction) => {
    const requestURL =
        '/api/signalRNotifications/ackReceived?' + convert2QueryString(payload);
    return ApiInstance.getInstance().get(requestURL);
};

export const ackRead = (payload: TNotificationAction) => {
    const requestURL =
        '/api/signalRNotifications/ackRead?' + convert2QueryString(payload);
    return ApiInstance.getInstance().get(requestURL);
};
