export type TSearchNotification = {
    page: number;
    pageSize: number;
    type?: number;
    userId?: number | string;
};

export type TNotificationAction = {
    userId: number | string;
    signalRNotificationId: number | string;
};

export enum NotificationTypeMode {
    All = 1,
    Received = 2,
    Read = 3,
    NotReceived = 4,
}
