import * as signalR from '@microsoft/signalr';
import { SIGNALR_URL } from './constants';

const getToken = async (): Promise<string> => {
    return localStorage.getItem('token') || '';
};

const createSignalRConnection = async () => {
    const token = await getToken();
    if (!token) {
        return null;
    }

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(SIGNALR_URL, {
            accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    return connection;
};

let signalRConnection: signalR.HubConnection | null = null;

export const startSignalRConnection = async () => {
    // need to check if connection is already established
    if (!signalRConnection) {
        signalRConnection = await createSignalRConnection();
    }
    if (signalRConnection?.state === signalR.HubConnectionState.Disconnected) {
        try {
            await signalRConnection?.start();
            // eslint-disable-next-line no-console
            console.warn('SignalR Connected.');
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('Error while establishing connection: ', err);
            setTimeout(startSignalRConnection, 5000);
        }
    } else {
        // eslint-disable-next-line no-console
        console.warn('SignalR connection is already established.');
    }
};

export const getSignalRConnection = () => signalRConnection;
