import React from 'react';
import { AutoTrackForm } from '../../../../components/form/form';
import { useCreateServices } from '../../hooks/useCreateServices';
import { AutoComplete, Flex, Form, Modal, SelectProps } from 'antd';
import { AutoTrackButton } from '../../../../components/button';
import styled from 'styled-components';
import { useNotification } from '../../../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux/slices/appInfo';
import { createCar, getCarsPaging } from '../../../../api/setting/cars';
import { Direction, SearchQueryParams } from '../../../../interface/paging';
import { removeUndefinedAttribute } from '../../../../utils';
import debouce from 'lodash/debounce';
import { AddLicensePlate } from '../../../components/add-license-plate';
import { CameraOCR } from '../../../components/camera-ocr';
import { ScannerType } from '../../../add-receipts/components/scanner-overlay';
import { TCar } from '../../type';

const RowField = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const UppercaseInput = styled(AutoComplete)`
    input {
        text-transform: uppercase;
    }
`;

export const SelectLicensePlateStep: React.FC = () => {
    const {
        carList,
        setCarList,
        setCarSelected,
        addLicensePlateForm,
        selectLicensePlateForm,
        onChangeStep,
    } = useCreateServices();
    const { showSuccess, showError } = useNotification();
    const dispatch = useDispatch();
    const [showNewCar, setShowNewCar] = React.useState(false);
    const Title: React.FC<Readonly<{ title?: string }>> = props => (
        <Flex align="center" justify="space-between">
            {props.title}
        </Flex>
    );
    const [licensePlate, setLicensePlate] = React.useState<string>('');

    const renderItem = (title: string) => ({
        value: title,
        label: <Flex align="center">{title}</Flex>,
    });

    const [carsOptions, setCarsOptions] = React.useState<
        SelectProps<object>['options']
    >([]);

    const onSubmit = React.useCallback(async () => {
        try {
            await selectLicensePlateForm.validateFields();
            const values = selectLicensePlateForm.getFieldsValue();
            const carSelection = carList.find(
                car => car.licensePlate === values.cardId
            );
            if (carSelection) {
                setCarSelected(carSelection);
            }
            onChangeStep(1);
        } catch (error) {
            // Handle error
        }
    }, [selectLicensePlateForm, carList]);

    const onSaveNewCar = React.useCallback(async () => {
        try {
            await addLicensePlateForm.validateFields();
            const values = addLicensePlateForm.getFieldsValue();
            const payload = {
                ...values,
                year: values.year.format('YYYY'),
            };
            dispatch(setLoading(true));
            const result = await createCar(payload);
            if (result.status === 201) {
                showSuccess('Save Car success', 'Car has been created');
                setShowNewCar(false);
                setCarList(prev => [...prev, result.data]);
                setCarSelected(result.data);
                selectLicensePlateForm.setFieldsValue({
                    cardId: result.data?.licensePlate,
                });
                setLicensePlate(result.data?.licensePlate);
                onChangeStep(1);
            }
        } catch (error) {
            // Handle error
            showError('Save Car failed', 'Car has not been created');
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, addLicensePlateForm, showSuccess]);

    // handle autocomplete search
    const loadData = async (val: string | undefined) => {
        try {
            const queryParams: SearchQueryParams = {
                page: 1,
                pageSize: 10,
                searchWord: val,
                sortBy: Direction.ASC,
                searchColum: 'LicensePlate',
            };
            const newPayload = removeUndefinedAttribute(queryParams);
            const response = await getCarsPaging(newPayload);
            if (response.status === 200) {
                const data = response.data?.data ?? [];
                const newData: TCar[] = [];
                data.forEach(item => {
                    if (
                        !newData.find(
                            car => car?.licensePlate === item?.licensePlate
                        )
                    ) {
                        newData.push(item);
                    }
                });
                setCarList(newData);
                const options = newData.map(item => ({
                    label: <Title title={item.model} />,
                    options: [renderItem(item.licensePlate)],
                }));
                setCarsOptions(options);
            }
        } catch (error) {
            // Handle error
        }
    };
    const handleSearchCars = (value: string) => {
        const newValue = value?.toUpperCase().trim();
        setLicensePlate(newValue);
        onSearchKeyword(newValue);
    };
    const onSearchCars = React.useCallback(async (value: string) => {
        try {
            await loadData(value);
        } catch (e) {
            // Handle error
        }
    }, []);
    const onSearchKeyword = React.useMemo(() => {
        return debouce(onSearchCars, 600);
    }, [onSearchCars]);

    React.useEffect(() => {
        loadData(undefined);
    }, []);

    const onSelectedTextImage = (val: any) => {
        if (!val) return;
        const newValue = val.toUpperCase().trim();
        const carSelection = carList.find(car => car.licensePlate === newValue);
        if (carSelection) {
            setCarSelected(carSelection);
            selectLicensePlateForm.setFieldsValue({
                cardId: newValue,
            });
            setLicensePlate(newValue);
            onChangeStep(1);
        } else {
            showError('License plate not found', 'Please try again');
        }
    };

    return (
        <div className="bg-white max-md:p-[16px] p-[32px] rounded-[8px]">
            <div className="mb-[24px] flex flex-col gap-[8px]">
                <div className="text-heading-6-bold text-high-em">
                    Choose license plate
                </div>
                <div className="text-med-em text-body">
                    Please select the license plate number of the vehicle you
                    want to add to the system
                </div>
            </div>
            <div className="flex w-full">
                <AutoTrackForm
                    form={selectLicensePlateForm}
                    name="licensePlate"
                    layout="vertical"
                    style={{ width: '100%' }}
                    labelCol={{ span: 10 }}
                    onFinish={onSubmit}
                    childNode={
                        <div className="flex flex-col gap-[20px] w-full">
                            <RowField>
                                <Form.Item
                                    name="cardId"
                                    label="Car"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter license plate',
                                        },
                                    ]}
                                >
                                    <UppercaseInput
                                        popupClassName="certain-category-search-dropdown"
                                        value={licensePlate}
                                        popupMatchSelectWidth={false}
                                        options={carsOptions}
                                        onSearch={handleSearchCars}
                                        placeholder="Search license plate"
                                        size="large"
                                    />
                                </Form.Item>
                                <div className="flex max-md:w-full gap-3 max-md:grid max-md:grid-cols-[1fr_50px]">
                                    <div className="max-md:p-0 pt-[28px] max-md:w-full">
                                        <AutoTrackButton
                                            type="primary"
                                            btnSize="sm"
                                            classess="max-md:w-full"
                                            btnType="sub_rounded_primary"
                                            onClick={() => {
                                                setShowNewCar(true);
                                            }}
                                        >
                                            Add a car
                                        </AutoTrackButton>
                                    </div>
                                    <div className="max-md:p-0 pt-[28px] max-md:w-full">
                                        <CameraOCR
                                            scannerType={ScannerType.PLATE}
                                            onSelectedTextImage={
                                                onSelectedTextImage
                                            }
                                        />
                                    </div>
                                </div>
                            </RowField>
                            <div className="flex justify-start">
                                <span className="max-md:w-full">
                                    <AutoTrackButton
                                        type="primary"
                                        htmlType="submit"
                                        btnSize="md"
                                        classess="max-md:w-full"
                                        btnType="primary"
                                    >
                                        Save & Next
                                    </AutoTrackButton>
                                </span>
                            </div>
                        </div>
                    }
                />
            </div>
            <Modal
                centered
                onCancel={() => setShowNewCar(false)}
                open={showNewCar}
                maskClosable={false}
                afterClose={() => {
                    addLicensePlateForm.resetFields();
                }}
                destroyOnClose
                title={null}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <AutoTrackButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowNewCar(false)}
                        >
                            Cancel
                        </AutoTrackButton>
                        <AutoTrackButton
                            onClick={onSaveNewCar}
                            btnType="primary"
                            btnSize="sm"
                        >
                            Save
                        </AutoTrackButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[20px] w-full overflow-hidden h-full items-start box-border">
                    <AddLicensePlate
                        addLicensePlateForm={addLicensePlateForm}
                    />
                </div>
            </Modal>
        </div>
    );
};
